export default {
    googleClientId: '898417736341-90po01ra9saqea3plhagjpbt313hng15.apps.googleusercontent.com',
    facebookAppId: '255103656292197',

    //azureClientId: 'a6e5be30-821b-4f0b-9ae1-934cf78fec0c',
    azureClientId: '9bb841d1-7ccb-430b-8678-434ac2cea01e',  //scg
    //azureClientId: 'b22fccac-5770-423d-ad11-bb9348e577f0', // agape
    //azureRedirectUri: 'https://sso.sonarinno.com/auth/signin', // agape
    //azureLogoutRedirectUri: 'https://sso.sonarinno.com/auth/signout' // agape
    azureRedirectUri: 'https://scg-sso.sonarinno.com/auth/signin', // scg
    azureLogoutRedirectUri: 'https://scg-sso.sonarinno.com/auth/signout' // scg
    //0120_BV2D_U03@scg.com Qt12rdfg
};