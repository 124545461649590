import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Models from '../models/AppState';

interface LoadAppState {
    type: 'LOAD'
}

interface LoadedSuccessAppState {
    type: 'LOADED_SUCCESS'
    res: Models.SocialAuth
}

interface LoadedFailAppState {
    type: 'LOADED_FAIL'
}

type KnownAction = LoadAppState | LoadedSuccessAppState | LoadedFailAppState;

export const actionCreators = {
    requestAppInit: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.appState) {
            fetch(`/v1/social-auth-env`, {
                method: 'GET',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    }
                    return response.json() as Promise<Models.SocialAuth>
                })
                .then(data => {
                    dispatch({ type: 'LOADED_SUCCESS', res: data });
                })
                .catch(error => {
                    dispatch({ type: 'LOADED_FAIL' });
                });

            dispatch({ type: 'LOAD' });
        }
    }
};

const unloadedState: Models.AppState = { isLoaded: undefined, socialAuth: undefined };

export const reducer: Reducer<Models.AppState> = (state: Models.AppState | undefined, incomingAction: Action): Models.AppState => {
    if (state == undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'LOAD':
            return {
                isLoaded: false,
                socialAuth: undefined,
            };
        case 'LOADED_SUCCESS':
            return {
                isLoaded: true,
                socialAuth: action.res,
            };
        case 'LOADED_FAIL':
            return {
                isLoaded: false,
                socialAuth: undefined,
            };

        default: return state;
    }
};