import * as React from 'react';
import { Card, CardBody, Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";

import { ApplicationState } from '../../store';
import * as Models from '../../models/register';
import * as Stores from '../../store/Register';

type Store = Models.RegisterState & typeof Stores.actionCreators;

class RegisterSuccess extends React.Component<any, {}> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get("email");
        const key = queryParams.get("key");

    }

    render() {
        return (
            <div >
                <Row className="mt-5">
                    <Col lg={2} />
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Alert color="success">
                                            SYSTEM SAID : Register Success!! — Pls, Authentication E-mail
                                        </Alert>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={2} />
                </Row>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.register,
    Stores.actionCreators
)(RegisterSuccess);
/*export default RegisterComponent*/