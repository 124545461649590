import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as RegisterModel from '../models/register';

interface RequestFunctionAction {
    type: 'REQUEST_REGISTER';
}

interface ReceiveFunctionAction {
    type: 'SUCCESS_REGISTER';
    status: number,
    response: RegisterModel.ResponseRegister
}

interface RegisterFail {
    type: 'FAIL_REGISTER';
    status: number,
    message: string
}

interface RegisterClear {
    type: 'CLEAR_REGISTER';
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | RegisterFail | RegisterClear

export const actionCreators = {
    /*requestFunction: (pageload: boolean, sid: string, version: number, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {*/
    requestFunction: (body: RegisterModel.Register, method: string, redirect: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        /*console.log(pageload + "-" + sid  + "-" + version + "-" + method);*/
        console.log(method);

        if (method == "POST") {
            var data = body;
            data.activation_channel = "email";
            body = data;
            console.log(body);

            fetch(`/v1/citizen/register?redirect=` + redirect, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WEB_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then(data => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'FAIL_REGISTER', status: 204, message: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'SUCCESS_REGISTER', status: 200, response: data });
                        })
                    }
                    dispatch({ type: 'REQUEST_REGISTER'});
                })
        }

        if (method == "CLEAR") {
            dispatch({ type: 'CLEAR_REGISTER' });
        }

    }
};

const unloadedState: RegisterModel.RegisterState = {
    isLoadingRegister: false, responseRegister: {} as any, statusRegister: 0 };

export const reducer: Reducer<RegisterModel.RegisterState> = (state: RegisterModel.RegisterState | undefined, incomingAction: Action): RegisterModel.RegisterState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_REGISTER':
            return {
                isLoadingRegister: false,
                statusRegister: 0
            };

        case 'SUCCESS_REGISTER':
            return {
                isLoadingRegister: true,
                responseRegister: action.response,
                statusRegister: action.status
            };
        case 'FAIL_REGISTER':
            return {
                isLoadingRegister: true,
                statusRegister: action.status,
                messageRegister: action.message
            };

        case 'CLEAR_REGISTER':
            return {
                isLoadingRegister: false,
                statusRegister: 0
            };

        default: return state;
    }
};