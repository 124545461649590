import * as React from 'react';
import { connect } from 'react-redux';
import AzureButtonComponent from './AzureButtonComponent';
import * as QueryString from "query-string"
import './style/Signin.css';

import * as AppStore from '../../store/AppState'
import * as SocialModel from '../../models/AppState'
import { ApplicationState } from "../../store";
import LoginButtonComponent from './LoginButtonComponent';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

import * as RedirectModels from '../../models/signin/Redirect';
import * as RedirectStore from '../../store/signin/Redirect';

import { FaUserLock } from 'react-icons/fa';
import colors from '../../default/Colors'
import LoginButtonPoolComponent from './LoginButtonPoolComponent';
var imgFile = require('../../images/F1 ERP_Logo.png');

type Store = RedirectModels.RedirectState & typeof RedirectStore.actionCreators

class SignInComponent extends React.Component<any, { version: string, username: string, password: string, currentUser: any, message: string, isLoginSuccess: boolean, token: string, endpoint: string, mode: string, socialAuth?: SocialModel.SocialAuth, openModalLogin: boolean }, Store> {
    constructor(props: any) {
        super(props)

        this.state = {
            username: '',
            password: '',
            currentUser: null,
            message: '',
            isLoginSuccess: false,
            token: '',
            endpoint: '',
            mode: '',
            version: '',
            socialAuth: undefined,
            openModalLogin: false
        }

        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
    }

    componentDidMount() {
        const { token, mode, redirect } = QueryString.parse(this.props.location.search);
        console.log(redirect)
        fetch("/v1/version")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        version: result.message
                    });
                },
                (error) => {
                }
            )

        this.props.requestAppInit();

        if (token != null) {
            var jwt = this.parseJwt(token)
            if (jwt.extra != undefined) {
                var extra = JSON.parse(jwt.extra)
                var callbackId = extra.callback_id;
                if (callbackId != undefined) {
                    console.log(callbackId);
                    fetch('/v1/citizen/auth/callback?id=' + callbackId, {
                        method: 'GET',
                    })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    token: token,
                                    endpoint: result.endpoint,
                                    mode: mode
                                })
                            },
                            (error) => {
                                console.log(error)
                            }
                        )
                }
            }
        } else {
            this.setState({
                mode: mode
            })
        }
        if (redirect != undefined && redirect != "") {
            this.props.requestFunctionRedirect(redirect, "SET");
        }
    }

    componentDidUpdate() {
        var { isLoaded, socialAuth } = this.props;
        if (isLoaded && this.state.socialAuth === undefined) {
            this.setState({
                socialAuth: socialAuth
            })
        }
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    private onChangeUserName(e: any) {
        const { name, value } = e.target
        this.setState({
            username: value
        })
    }

    private onChangePassword(e: any) {
        const { name, value } = e.target
        this.setState({
            password: value
        })
    }

    rememberMe = (e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            localStorage.setItem('SSO-WEB_REMEMBER', 'true')
        } else {
            localStorage.removeItem('SSO-WEB_REMEMBER')
        }
    }

    //private testCallback() {
    //    var aa = {
    //        message: 'window.postMessage testCallback'
    //    };

    //    (window as any).chrome.webview.postMessage('testCallback');
    //    //window.postMessage(aa, '*');

    //    console.log(aa);
    //}

    onOpenModalLogin = () => {
        this.setState({
            openModalLogin: !this.state.openModalLogin
        })
    }

    render() {
        let loginObject = this.state;
        var { isLoaded, socialAuth } = this.props;

        if (!isLoaded) {
            return <div></div>
        }

        return (
            <div>
                <form >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 signin-font">
                            <div className="div-logo">
                                < img src={imgFile} className="logo-login" alt="file" />
                            </div>
                            <br />
                            <br />
                            {/*<h3 >Sign In</h3>

                             <div className="form-group">
                                <label style={{ fontFamily: "sans-serif" }}>Username</label>
                                <input type="Text" className="form-control" placeholder="Enter username" name="username" onChange={this.onChangeUserName} />
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={this.onChangePassword} />
                            </div> */}
                            {/*<div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={(e) => this.rememberMe(e)} />
                                        <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex justify-content-end">
                                    <Link to="/forgotpassword">
                                        <a>Forgot Password</a>
                                    </Link>
                                </div>
                            </div>
                        </div>*/}

                            {/*<LoginButtonComponent loginObject={loginObject} history={this.props.history} />*/}
                            {/* <LoginButtonF1Component loginObject={loginObject} /> */}
                            {/* <LoginButtonPoolComponent loginObject={loginObject} /> */}
                            <button type="button" className="btn btn-info btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }} onClick={this.onOpenModalLogin}>
                                <FaUserLock style={{ width: "1.5em", height: "1.5em", marginRight: "10px" }} />
                                <span><b>&nbsp;Login With Local</b></span>
                                
                            </button>
                            <div className="hr-sect" style={{ fontFamily: "sans-serif" }}>OR</div>
                            {/*<p className="forgot-password text-right">
                        Forgot <a href="#">password?</a>
                    </p>*/}

                            {socialAuth &&
                                <div>
                                    {/*{socialAuth.facebook_app_id &&
                                <div>
                                    <FacebookButtonComponent loginObject={loginObject} />
                                </div>
                            }
                            {socialAuth.google_client_id &&
                                <div>
                                    <GoogleButtonComponent loginObject={loginObject} />
                                </div>
                            }*/}
                                    {socialAuth.azure_client_id &&
                                        <div>
                                            <AzureButtonComponent loginObject={loginObject} history={this.props.history} />
                                        </div>
                                    }
                                </div>
                            }

                            {/* <FacebookButtonComponent loginObject={loginObject} />

                        <GoogleButtonComponent loginObject={loginObject} /> 

                        <AzureButtonComponent loginObject={loginObject} /> */}

                            {/*<Link to="/citizen/register">
                        <button className="mt-3 btn btn-info btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }}><b>Register</b></button>
                    </Link>*/}

                            <span style={{ fontSize: '8pt', paddingLeft: '5px', float: 'right' }}>Version {this.state.version}</span>

                            {/* <button type="button" className="mt-3 btn btn-info btn-block" onClick={this.testCallback}><b>Test Callback</b></button> */}

                        </div>
                    </div>
                </form>

                <Modal isOpen={this.state.openModalLogin} toggle={() => this.onOpenModalLogin()} size="xl">
                    <ModalHeader toggle={() => this.onOpenModalLogin()}></ModalHeader>
                    <ModalBody>
                        <form >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                                <div className="col-lg-6 col-md-8 col-sm-12 signin-font">
                                    <div className="div-logo">
                                        < img src={imgFile} className="logo-login" alt="file" />
                                    </div>
                                    <h3 >Sign In</h3>

                                    <div className="form-group">
                                        <label style={{ fontFamily: "sans-serif" }}>Username</label>
                                        <input type="Text" className="form-control" placeholder="Enter username" name="username" onChange={this.onChangeUserName} />
                                    </div>

                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={this.onChangePassword} />
                                    </div>

                                    <LoginButtonPoolComponent loginObject={loginObject} />

                                </div>
                            </div>
                         </form >
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState, ...state.Redirect }),
    ({ ...AppStore.actionCreators, ...RedirectStore.actionCreators }))(SignInComponent);

//export default SignInComponent