import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import { Spinner } from 'reactstrap';
import Loader from 'react-loader-advanced';
import './custom.css'

export default () => (
    <Loader show={true} message={<Spinner color="dark" />}>
        <Layout>
            {  /*<Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />*/}
        </Layout>
    </Loader>
);
