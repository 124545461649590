import * as SigninModel from './../models/signin/Signin';
import * as EditCitizenModel from '../models/EditCitizen';
import * as RegisterModel from '../models/register';

import * as Signin from './signin/Signin';
import * as EditCitizen from './EditCitizen';
import * as Register from './Register';

import * as SignInF1Model from './../models/signin/SignInF1';
import * as SignInF1 from './signin/SignInF1';

import * as SignInPoolModel from './../models/signin/SignInPool';
import * as SignInPool from './signin/SignInPool';

import * as CallbackConfigMaster from './callbackconfig/CallbackMaster'
import * as ModelsCallbackConfigMaster from '../models/callbackconfig/CallbackMaster'

import * as CallbackTokens from './callbackconfig/CallbackTokens'
import * as ModelsCallbackTokens from '../models/callbackconfig/CallbackTokens'

import * as CreateCallback from './callbackconfig/CreateCallback'
import * as ModelsCreateCallback from '../models/callbackconfig/CreateCallback'

import * as AppState from './AppState'
import * as ModelsAppState from '../models/AppState'

import * as EmailAuthenticationModels from './../models/signin/EmailAuthentication'
import * as EmailAuthenticationStore from './signin/EmailAuthentication'

import * as ForgotPasswordModels from './../models/signin/ForgotPassword'
import * as ForgotPasswordStore from './signin/ForgotPassword'

import * as ResetPasswordModels from './../models/signin/ResetPassword'
import * as ResetPasswordStore from './signin/ResetPassword'

import * as UserExpireModels from './../models/signin/UserExpire'
import * as UserExpireStore from './signin/UserExpire'

import * as RedirectModels from './../models/signin/Redirect'
import * as RedirectStore from './signin/Redirect'

// The top-level state object
export interface ApplicationState {
    signin: SigninModel.SigninState | undefined;
    signInF1: SignInF1Model.SigninState | undefined;
    signInPool: SignInPoolModel.SigninState | undefined;
    editCitizen: EditCitizenModel.EditCitizenState | undefined;
    register: RegisterModel.RegisterState | undefined;
    callbackConfig: ModelsCallbackConfigMaster.CallbackMasterState | undefined;
    callbackTokens: ModelsCallbackTokens.CallbackTokenState | undefined;
    createCallback: ModelsCreateCallback.SaveCallbackState | undefined;
    appState: ModelsAppState.AppState | undefined;
    EmailAuthentication: EmailAuthenticationModels.EmailAuthenticationState | undefined;
    ForgotPassword: ForgotPasswordModels.GetForgotPasswordState | undefined;
    ResetPassword: ResetPasswordModels.GetResetPasswordState | undefined;
    UserExpire: UserExpireModels.UserExpireState | undefined;
    Redirect: RedirectModels.RedirectState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    signin: Signin.reducer,
    signInF1: SignInF1.reducer,
    signInPool: SignInPool.reducer,
    editCitizen: EditCitizen.reducer,
    register: Register.reducer,
    callbackConfig: CallbackConfigMaster.reducer,
    callbackTokens: CallbackTokens.reducer,
    createCallback: CreateCallback.reducer,
    appState: AppState.reducer,
    EmailAuthentication: EmailAuthenticationStore.reducer,
    ForgotPassword: ForgotPasswordStore.reducer,
    ResetPassword: ResetPasswordStore.reducer,
    UserExpire: UserExpireStore.reducer,
    Redirect: RedirectStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
