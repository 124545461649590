import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as SigIn from '../../models/signin/UserExpire';
import { error } from 'console';
import * as ExpireUserModels from '../../models/signin/UserExpire';

interface BeforeGetExpireUser {
    type: 'GET_EXPIREUSER';
}

interface GetExpireUser {
    type: 'EXPIREUSER_SUCCESS';
    status: number
    response: ExpireUserModels.ResUserExpire
}

interface FailExpireUser {
    type: 'EXPIREUSER_FAIL'
    message: string,
    status: number
}

interface CLEARExpireUser {
    type: 'EXPIREUSER_CLEAR'
}

type KnownAction = GetExpireUser | BeforeGetExpireUser | FailExpireUser | CLEARExpireUser

export const actionCreators = {
    requestUserExpire: (bool: boolean, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('actionCreators : requestExpireUser')
        const appState = getState();
        if (appState && appState.UserExpire) {
            if (bool && method == "GET") {
                fetch(`/v1/citizen/expirationdate`, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('SSO-WEB_APPLICATION'),
                    },
                })
                    .then(response => {
                        return response
                    })
                    .then(data => {
                        if (!data.ok) {
                            data.json().then(err => {
                                console.log(err.message)
                                dispatch({ type: 'EXPIREUSER_FAIL', status: 204, message: err.message });
                            })
                        } else {
                            data.json().then(data => {
                                console.log(data)
                                dispatch({ type: 'EXPIREUSER_SUCCESS', status: 200, response: data });
                            })
                        }
                    })
                dispatch({ type: 'GET_EXPIREUSER' });
            } else if (method == "CLEAR") {
                dispatch({ type: 'EXPIREUSER_CLEAR' });
            }
        }
    }

};

const unloadedState: ExpireUserModels.UserExpireState = {
    isLoadUserExpire: false, responseUserExpire: {} as any, statusUserExpire: 0, messageUserExpire: ''
};

export const reducer: Reducer<ExpireUserModels.UserExpireState> = (state: ExpireUserModels.UserExpireState | undefined, incomingAction: Action): ExpireUserModels.UserExpireState => {
    if (state === undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'GET_EXPIREUSER':
            console.log('reducer GET_EXPIREUSER')
            return {
                isLoadUserExpire: false,
                messageUserExpire: '',
                statusUserExpire: 0,
                responseUserExpire: {} as any
            };
        case 'EXPIREUSER_SUCCESS':
            console.log('reducer EXPIREUSER_SUCCESS')
            return {
                isLoadUserExpire: true,
                statusUserExpire: 200,
                responseUserExpire: action.response
            };
        case 'EXPIREUSER_FAIL':
            console.log('reducer EXPIREUSER_FAIL')
            return {
                isLoadUserExpire: true,
                messageUserExpire: action.message,
                statusUserExpire: 400
            };
        case 'EXPIREUSER_CLEAR':
            console.log('reducer EXPIREUSER_CLEAR')
            return {
                isLoadUserExpire: false,
                messageUserExpire: '',
                statusUserExpire: 0,
                responseUserExpire: {} as any
            };

        default: return state;
    }
};