import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as SignIn from '../../models/signin/SignInPool';

interface RequestFunctionAction {
    type: 'REQUEST_POOL_SIGNIN';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_POOL_SIGNIN';
    model?: SignIn.ResponseSignIn;
    message: any;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (username: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            var body = {
                'username': username,
                'password': password
            }
            console.log(body)

            fetch(`/v1/citizen/pool/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    return response.json() as Promise<SignIn.ResponseSignIn>
                })
                .then(data => {
                    if (JSON.stringify(data.accessToken) != undefined && JSON.stringify(data.accessToken) != "" && JSON.stringify(data.accessToken) != null) {
                        localStorage.setItem('SSO-WEB_APPLICATION', data.accessToken);
                        dispatch({ type: 'RECEIVE_POOL_SIGNIN', model: data, message: "" });
                    } else {
                        dispatch({ type: 'RECEIVE_POOL_SIGNIN', model: data, message: "" });
                    }
                })
                .catch(error => {
                    dispatch({ type: 'RECEIVE_POOL_SIGNIN', model: undefined, message: error });
                });

            dispatch({ type: 'REQUEST_POOL_SIGNIN' });
        }

    }

};

const unloadedState: SignIn.SigninState = { isLoadingSignin: false, isLoginSuccess: false, responseSignin: undefined };

export const reducer: Reducer<SignIn.SigninState> = (state: SignIn.SigninState | undefined, incomingAction: Action): SignIn.SigninState => {
    if (state === undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_POOL_SIGNIN':
            console.log('reducer RECEIVE_POOL_SIGNIN')
            return {
                isLoadingSignin: true,
                isLoginSuccess: false
            };
        case 'RECEIVE_POOL_SIGNIN':
            console.log('reducer RECEIVE_POOL_SIGNIN', action.model)
            return {
                isLoadingSignin: false,
                isLoginSuccess: true,
                responseSignin: action.model,
                message: action.message
            };

        default: return state;
    }
};