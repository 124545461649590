import * as React from 'react';
import { Card, CardBody, Row, Col, Alert, Button } from 'reactstrap';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";

import { ApplicationState } from '../../store';
import * as EmailAuthenticationModels from '../../models/signin/EmailAuthentication';
import * as EmailAuthenticationStore from '../../store/signin/EmailAuthentication';
import Swal from 'sweetalert2'
import colors from '../../default/Colors'

type Store = EmailAuthenticationModels.EmailAuthenticationState & typeof EmailAuthenticationStore.actionCreators;

class EmailAuthentication extends React.Component<any, { key: string, checkAuthen: boolean, redirectTo: string }, Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            key: "",
            checkAuthen: true,
            redirectTo: ""
        }
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const key = queryParams.get("key");
        console.log(key)
        var redirect = queryParams.get("redirect");
        if (redirect != null && redirect != undefined) {
            this.setState({
                redirectTo: redirect
            })
        }
        if (key != undefined) {
            this.setState({
                key: key
            })
        }
    }

    handleClickAccept = () => {
        if (this.state.key != "" && this.state.checkAuthen) {
            console.log('handleClickAccept :' + this.state.key)
            this.props.requestEmailAuthentication(this.state.checkAuthen, "GET", this.state.key);
            this.setState({
                checkAuthen : false
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
    }

    componentDidUpdate(nextProps, nextState) {
        const { isLoadEmailAuthen, responseAuthen, statusAuthen, messageAuthen } = this.props
        if (isLoadEmailAuthen && statusAuthen == 200 && !this.state.checkAuthen) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'Success!',
                text: 'Authentication Success',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestEmailAuthentication(true, "CLEAR", this.state.key);
                    if (this.state.redirectTo != "" && this.state.redirectTo != null) {
                        window.location.href = this.state.redirectTo;
                    } else {
                        this.props.history.push('/auth/signin');
                    }
                }
            })
        } else if (isLoadEmailAuthen && statusAuthen == 400 && !this.state.checkAuthen) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(messageAuthen)
            Swal.fire({
                title: 'Error!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestEmailAuthentication(true, "CLEAR", this.state.key);
                }
            })
        }
    }

    render() {
        return (
            <div >
                <Row className="mt-5">
                    <Col lg={2} />
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Alert color="success">
                                            <Row>
                                                <Col>
                                                    <div className="text-center">
                                                        SYSTEM SAID : Register Success!! — Pls, Authentication E-mail
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col lg={4}/>
                                                <Col>
                                                    <Button color="primary" className="btn-block" style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }} onClick={() => this.handleClickAccept()}> Accept Authentication</Button>
                                                </Col>
                                                <Col lg={4} />
                                            </Row>
                                        </Alert>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={2} />
                </Row>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.EmailAuthentication,
    EmailAuthenticationStore.actionCreators
)(EmailAuthentication);
/*export default RegisterComponent*/