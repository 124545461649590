import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as EmailAuthenModels from '../../models/signin/EmailAuthentication';

interface BeforeGetEmailAuthen {
    type: 'GET_EMAILAUTHEN';
}

interface GetEmailAuthen {
    type: 'EMAILAUTHEN_SUCCESS';
    status: number
}

interface FailEmailAuthen {
    type: 'EMAILAUTHEN_FAIL'
    message: string,
    status: number
}

interface CLEAREmailAuthen {
    type: 'EMAILAUTHEN_CLEAR'
}

type KnownAction = GetEmailAuthen | BeforeGetEmailAuthen | FailEmailAuthen | CLEAREmailAuthen

export const actionCreators = {
    requestEmailAuthentication: (bool: boolean, method: string, activate_key: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('actionCreators : requestEmailAuthentication')
        const appState = getState();
        if (appState && appState.EmailAuthentication) {
            if (bool && method == "GET") {
                fetch(`/v1/citizen/activate/email?activation_key=` + activate_key, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        return response
                    })
                    .then(data => {
                        if (!data.ok) {
                            data.json().then(err => {
                                console.log(err.message)
                                dispatch({ type: 'EMAILAUTHEN_FAIL', status: 204, message: err.message });
                            })
                        } else {
                            console.log(data)
                            dispatch({ type: 'EMAILAUTHEN_SUCCESS', status: 200 });
                        }
                    })
                dispatch({ type: 'GET_EMAILAUTHEN' });
            } else if (method == "CLEAR") {
                dispatch({ type: 'EMAILAUTHEN_CLEAR' });
            }
        }
    }

};

const unloadedState: EmailAuthenModels.EmailAuthenticationState = { isLoadEmailAuthen: false, responseAuthen: '', statusAuthen: 0, messageAuthen: '' };

export const reducer: Reducer<EmailAuthenModels.EmailAuthenticationState> = (state: EmailAuthenModels.EmailAuthenticationState | undefined, incomingAction: Action): EmailAuthenModels.EmailAuthenticationState => {
    if (state === undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'GET_EMAILAUTHEN':
            console.log('reducer GET_EMAILAUTHEN')
            return {
                isLoadEmailAuthen: false
            };
        case 'EMAILAUTHEN_SUCCESS':
            console.log('reducer EMAILAUTHEN_SUCCESS')
            return {
                isLoadEmailAuthen: true,
                statusAuthen: 200
            };
        case 'EMAILAUTHEN_FAIL':
            console.log('reducer EMAILAUTHEN_FAIL')
            return {
                isLoadEmailAuthen: true,
                messageAuthen: action.message,
                statusAuthen: 400
            };
        case 'EMAILAUTHEN_CLEAR':
            console.log('reducer EMAILAUTHEN_CLEAR')
            return {
                isLoadEmailAuthen: false,
                messageAuthen: '',
                statusAuthen: 0,
                responseAuthen: ''
            };

        default: return state;
    }
};