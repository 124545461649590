import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { UserAgentApplication } from 'msal';
import * as SigninModels from '../../models/signin/Signin';
import Swal from 'sweetalert2'
//import { config } from './azure/Config';
import { normalizeError, getUserProfile } from './azure/utils/MSUtils';

import * as Stores from '../../store/signin/Signin';
import * as Models from '../../models/signin/Signin';
import * as ForgotPasswordModels from '../../models/signin/ForgotPassword';
import * as ForgotPasswordStore from '../../store/signin/ForgotPassword';
import colors from '../../default/Colors'

type Store = Models.SigninState & typeof Stores.actionCreators | ForgotPasswordModels.GetForgotPasswordState & typeof ForgotPasswordStore.actionCreators;

//const config = {
//    clientId: this.pro.azureClientId,
//    redirectUri: Utility.azureRedirectUri,
//    postLogoutRedirectUri: Utility.azureLogoutRedirectUri,
//    scopes: [
//        'user.read'
//    ],
//};

//export const userAgentApplication = new UserAgentApplication({
//    auth: {
//        //authority: 'https://login.microsoftonline.com/common',
//        //authority: 'https://login.microsoftonline.com/a-gape.com',
//        clientId: config.clientId,
//        redirectUri: config.redirectUri,
//        postLogoutRedirectUri: config.postLogoutRedirectUri,
//    },
//    cache: {
//        cacheLocation: "sessionStorage",
//        storeAuthStateInCookie: true
//    }
//});

class AzureButtonComponent extends React.Component<any, { isLoading: boolean, isLoginSuccess: string, isOpen: boolean, error: null, isAuthenticated: boolean, data: SigninModels.SignInData, typeLogin: string }, Store> {
    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isLoading: false,
            isLoginSuccess: "",
            isOpen: false,
            error: null,
            isAuthenticated: false,
            data: {
                social_id: "",
                first_name: "",
                last_name: "",
                email: ""
            },
            typeLogin: ""
        };
        this.toggle = this.toggle.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.getUserAgentApplication = this.getUserAgentApplication.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    getUserAgentApplication() {
        var { loginObject } = this.props
        return new UserAgentApplication({
            auth: {
                //authority: 'https://login.microsoftonline.com/common',
                //authority: 'https://login.microsoftonline.com/scg.com',
                authority: loginObject.socialAuth.azure_authority_uri,
                clientId: loginObject.socialAuth.azure_client_id,
                redirectUri: loginObject.socialAuth.azure_login_redirect_uri,
                postLogoutRedirectUri: loginObject.socialAuth.azure_logout_redirect_uri,
            },

            //auth: {
            //    authority: 'https://login.microsoftonline.com/common',
            //    //authority: 'https://login.microsoftonline.com/scg.com',
            //    clientId: "a6e5be30-821b-4f0b-9ae1-934cf78fec0c",
            //    redirectUri: "http://localhost:8082",
            //    postLogoutRedirectUri: loginObject.socialAuth.azure_logout_redirect_uri,
            //},
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: true
            }
        });
    }
    

    async login() {
        var userAgentApplication = this.getUserAgentApplication();
        var scopes = ['user.read'];

        try {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            await userAgentApplication.loginPopup(
                {
                    scopes: scopes,
                    prompt: "select_account"
                });

            const user = await getUserProfile(userAgentApplication, scopes);

            this.setState({
                isAuthenticated: true,
                data: {
                    email: user.mail || user.userPrincipalName,
                    social_id: user.id,
                    first_name: user.givenName,
                    last_name: user.surname
                },
                error: null
            });
            console.log(this.state.data)

            this.props.requestFunction("POST", "", "", "azure", this.state.data);
        }
        catch (err) {
            this.setState({
                isAuthenticated: false,
                data: {
                    social_id: "",
                    first_name: "",
                    last_name: "",
                    email: ""
                },
            });
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }
    }

    logout() {
        var userAgentApplication = this.getUserAgentApplication();
        userAgentApplication.logout();
    }

    componentDidUpdate(nextProps, nextState) {
        let { mode, endpoint, username } = this.props.loginObject;
        const { isLoadGetForgotPassword, statusGetForgotPassword, messageGetForgotPassword } = this.props
        if (localStorage.getItem('SSO-WEB_LOGIN_TYPE') != this.state.typeLogin) {
            var loginType = localStorage.getItem('SSO-WEB_LOGIN_TYPE')
            if (loginType) {
                this.setState({
                    typeLogin: loginType
                })
            }
            //else
            //{
            //    this.setState({
            //        typeLogin: ''
            //    })
            //}
        }
        if (this.state.typeLogin == "azure") {
            if (this.props.isLoginSuccess) {
                if (this.props.statusLogin == 200) {
                    if (this.props.responseSignin.token != "" && this.props.responseSignin.token != undefined && this.props.responseSignin.token != null) {
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                        if (endpoint) {
                            console.log(localStorage.getItem('SSO-WEB_APPLICATION'))
                            var path = endpoint + "?token=" + localStorage.getItem('SSO-WEB_APPLICATION');

                            if (mode) {
                                path += '&mode=' + mode;
                            }
                            this.props.requestFunction("CLEAR", "", "", "azure", this.state.data);
                            return window.top.location.href = path;
                        } else {
                            this.props.requestFunction("CLEAR", "", "", "azure", this.state.data);
                            this.props.history.push('/')
                        }
                    }
                } else if (this.props.statusLogin != 200) {
                    this.props.requestFunction('', '')
                    this.setState({ isLoading: false })
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    var msg = JSON.parse(this.props.messageSingin)
                    Swal.fire({
                        title: 'Error!',
                        text: msg.message,
                        titleText: 'Please enter re-send email to authentication',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 're-send email',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.props.requestFunction("CLEAR", "", "", "", "");
                            var dataReq: any = {}
                            dataReq['username'] = username // ใช้ username แทน
                            console.log(dataReq)
                            this.props.requestForgotPassword(true, "GET", dataReq);
                            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        }
                    })
                }
            }
            //Check forgot password
            if (isLoadGetForgotPassword && statusGetForgotPassword == 200) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                Swal.fire({
                    title: 'Success!',
                    text: 'A request has been recived to your email , Pls check email',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.props.requestForgotPassword(true, "CLEAR", '');
                        this.props.history.push('/auth/signin');
                    }
                })
            } else if (isLoadGetForgotPassword && statusGetForgotPassword == 400) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                //var msg = JSON.parse(messageGetForgotPassword)
                Swal.fire({
                    title: 'Error!',
                    text: messageGetForgotPassword,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        this.props.requestForgotPassword(true, "CLEAR", '');
                    }
                })
            }
        }

    }

    render() {
        let { mode, endpoint } = this.props.loginObject;
        let { isLoading } = this.state;
        if (this.state.error) {
            console.log(this.state.error);
        }
        //console.log("isLoading")
        //console.log(isLoading)
        //console.log(this.props.responseSignin)

        return (

            <button type="button" onClick={() => this.login()} style={{ backgroundColor: colors.btnSSO_Blue, color: "#ffffff" }} className="mt-3 btn btn-light btn-block" disabled={isLoading}><img style={{ paddingRight: "15px" }} src={require('../../images/icon/microsoft.png')} />
                {isLoading && (
                    <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {isLoading && <span>Loading Data from Server</span>}
                {!isLoading && <span><b>Login with Azure AD</b></span>}
            </button>
        );
    }
}


export default connect(
    (state: ApplicationState) => ({ ...state.signin, ...state.ForgotPassword }),
    ({ ...Stores.actionCreators, ...ForgotPasswordStore.actionCreators })
)(AzureButtonComponent);