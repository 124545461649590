import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as Redirect from '../../models/signin/Redirect';

interface RequestFunctionAction {
    type: 'CLEAR_REDIRECT_URL';
}

interface ReceiveFunctionAction {
    type: 'SET_REDIRECT_URL';
    redirect: any;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunctionRedirect: (redirect_to: string, type: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log("requestFunctionRedirect")
        console.log(redirect_to + "   " + type)
        if (appState) {
            if (type == "SET") {
                dispatch({ type: 'SET_REDIRECT_URL', redirect: redirect_to });
            }
            else if (type == "CLEAR") {
                dispatch({ type: 'CLEAR_REDIRECT_URL' });
            } 
        }

    }

};

const unloadedState: Redirect.RedirectState = { isSetRedirect: false, isRedirectSuccess: false, redirect: undefined };

export const reducer: Reducer<Redirect.RedirectState> = (state: Redirect.RedirectState | undefined, incomingAction: Action): Redirect.RedirectState => {
    if (state === undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'CLEAR_REDIRECT_URL':
            console.log('reducer RECEIVE_POOL_SIGNIN')
            return unloadedState;
        case 'SET_REDIRECT_URL':
            return {
                isSetRedirect: false,
                isRedirectSuccess: true,
                redirect: action.redirect
            };

        default: return state;
    }
};