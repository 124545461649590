import * as React from 'react';
import { Container,Card, CardBody, Row, Col, Alert, Button, CardHeader, Badge, FormGroup, Label, Input, FormText, CardFooter } from 'reactstrap';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from "react-tooltip";
import { isOkPass } from '../Common/Common'

import { ApplicationState } from '../../store';
import * as ResetPasswordModels from '../../models/signin/ResetPassword';
import * as ResetPasswordStore from '../../store/signin/ResetPassword';
import Swal from 'sweetalert2'
import colors from '../../default/Colors'

type Store = ResetPasswordModels.GetResetPasswordState & typeof ResetPasswordStore.actionCreators;

class RePassword extends React.Component<any, { key: string, checkReset: boolean, email: string, passValidate: any, modelRegister: any, checkDisablebtn: boolean, redirectTo: string}, Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            key: "",
            checkReset: true,
            email: '',
            passValidate: {
                error: '',
                length: false,
                numLower: false,
                numNums: false,
                numSpecials: false,
                numUpper: false,
                result: false
            },
            modelRegister: {
                password: '',
                rePassword: ''
            },
            checkDisablebtn: true,
            redirectTo: ""
        }
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const key = queryParams.get("key");
        console.log(key)
        var redirect = queryParams.get("redirect");
        if (redirect != null && redirect != undefined) {
            this.setState({
                redirectTo: redirect
            })
        }
        if (key != undefined) {
            this.setState({
                key: key
            })
        }
    }

    handleClickAccept = () => {
        if (this.state.key != "" && this.state.checkReset) {
            console.log('handleClickAccept :' + this.state.key)
            var dataReq: any = {}
            dataReq['password'] = this.state.modelRegister['password']
            dataReq['re_password'] = this.state.modelRegister['rePassword']
            dataReq['email'] = ""
            this.props.requestResetPassword(this.state.checkReset, "GET", dataReq ,this.state.key);
            this.setState({
                checkReset: false
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
    }

    handleChangeValue = (e: any) => {
        var data = this.state.modelRegister;
        if (e.target.id == "password") {
            data.password = e.target.value;
            var ans = isOkPass(e.target.value)
            console.log(ans)
            this.setState({
                passValidate: ans
            })
        }
        if (e.target.id == "rePassword") {
            data.rePassword = e.target.value;
        }
        if (this.state.modelRegister.password != "" || this.state.modelRegister.rePassword != "") {
            if (this.state.modelRegister.password != this.state.modelRegister.rePassword) {
                this.setState({
                    checkDisablebtn: true
                });
            } else {
                this.setState({
                    checkDisablebtn: false
                });
            }
        } else {
            this.setState({
                checkDisablebtn: true
            });
        }
    }

    componentDidUpdate(nextProps, nextState) {
        const { isLoadGetResetPassword, responseGetResetPassword, statusGetResetPassword, messageGetResetPassword } = this.props
        if (isLoadGetResetPassword && statusGetResetPassword == 200 && !this.state.checkReset) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'Success!',
                text: 'Reset Password Success',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestResetPassword(true, "CLEAR", this.state.key);
                    if (this.state.redirectTo != "" && this.state.redirectTo != null) {
                        window.location.href = this.state.redirectTo;
                    } else {
                        this.props.history.push('/auth/signin');
                    } 
                }
            })
        } else if (isLoadGetResetPassword && statusGetResetPassword == 400 && !this.state.checkReset) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(messageGetResetPassword)
            Swal.fire({
                title: 'Error!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestResetPassword(true, "CLEAR", this.state.key);
                }
            })
        }
    }

    render() {
        return (
            <Container >
                <Row className="mt-5">
                    <Col lg={3} />
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col className="text-center">
                                        <h3>Reset Password</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>New Password :</Label>
                                            <Input type="password" name="password" id="password" onChange={this.handleChangeValue} autoComplete="new-password" data-tip data-for='global' valid={this.state.passValidate['result']} invalid={!this.state.passValidate['result'] ? true : false} />
                                            <ReactTooltip id='global' place="left" type="dark" effect="solid">
                                                <ul>
                                                    <li style={{ color: this.state.passValidate['length'] ? 'green' : 'white' }}> Minimum Password 8 character</li>
                                                    <li style={{ color: this.state.passValidate['numUpper'] ? 'green' : 'white' }}>At least 1 UPPER CASE</li>
                                                    <li style={{ color: this.state.passValidate['numLower'] ? 'green' : 'white' }}>At least 1 LOWWER CASE</li>
                                                    <li style={{ color: this.state.passValidate['numSpecials'] ? 'green' : 'white' }}>At least 1 Special CASE</li>
                                                </ul>
                                            </ReactTooltip>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup className="position-relative">
                                            <Label>Re-Password :</Label>
                                            <Input type="password" name="rePassword" id="rePassword" onChange={this.handleChangeValue} autoComplete="new-password" invalid={this.state.checkDisablebtn} valid={!this.state.checkDisablebtn ? true : false} />
                                            <FormText>Please enter match your password.</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col>
                                        <Button className="mt-3 btn btn-info btn-block" disabled={this.state.checkDisablebtn} style={{ backgroundColor: colors.btnSSO_Orange, borderColor: colors.btnSSO_Orange }} onClick={this.handleClickAccept}>Accept</Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg={3} />
                </Row>
            </Container>
        );
    }
}

export default connect((state: ApplicationState) => state.ResetPassword,
    ResetPasswordStore.actionCreators
)(RePassword);
/*export default RegisterComponent*/