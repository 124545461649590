import * as React from 'react';
import { Route, Switch } from 'react-router';
import SubLayout from './SubLayout';
import { PrivateRoute } from './PrivateRoute';
import SignInComponent from './auth/SigninComponent';
import SignoutComponent from './auth/SignOutComponent';
import Home from '../components/Home';
import EditCitizenComponent from './editcitizen/EditCitizenComponent';
import RegisterComponent from './register/RegisterComponent';
import AddConfigMaster from './config/AddConfigMaster';
import LogoutAzure from './auth/LogoutAzure';
import RegisterSuccess from './register/RegisterSuccess'
import EmailAuthentication from './register/EmailAuthentication'
import ForgotPassword from './register/ForgotPassword'
import RePassword from './register/RePassword'
import SignInComponentV2 from './auth/SigninComponentV2';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Switch>
            <Route path='/auth/signin' component={SignInComponent} />
            <Route path='/auth/signin_v2' component={SignInComponentV2} />
            <Route path='/authemail' component={EmailAuthentication} />
            <Route path='/forgotpassword' component={ForgotPassword} />
            <Route path='/re-Password' component={RePassword} />
            <Route path='/citizen/register' component={RegisterComponent} />
            <Route path='/citizen/register-success' component={RegisterSuccess} />
            <Route path='/citizen/editcitizenV2' component={EditCitizenComponent} />
            <Route path='/config/callback' component={AddConfigMaster} />
            <SubLayout>
                <PrivateRoute exact path='/' component={Home} />
                <PrivateRoute path='/citizen/editcitizen' component={EditCitizenComponent} />
                <PrivateRoute path='/auth/signout' component={SignoutComponent} />
                <PrivateRoute path='/addconfig' component={AddConfigMaster} />
                <PrivateRoute path='/auth/signoutAzure' component={LogoutAzure} />
            </SubLayout>
        </Switch>
    </React.Fragment>
);
