import * as React from 'react';
import { Redirect } from "react-router";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/signin/Signin';
import * as Models from '../../models/signin/Signin';
import { config } from './azure/Config';
import { UserAgentApplication } from 'msal';
import * as EditCitizenModels from '../../models/EditCitizen';
import * as EditCitizenStores from '../../store/EditCitizen';

export const userAgentApplication = new UserAgentApplication({
    auth: {
        clientId: config.clientId,
        redirectUri: config.redirectUri,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
});

type EditCitizenStore = EditCitizenModels.EditCitizenState & typeof Stores.actionCreators;
type Store = Models.SigninState & typeof Stores.actionCreators.requestFunction;

class LogoutAzure extends React.Component<any, Store & EditCitizenStore> {

    constructor(props: any) {
        super(props);
    }

    public render() {

        userAgentApplication.logout();

        return (
            <>
                {/*<Redirect to="/auth/signout" />*/}
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) =>
        ({ ...state.signin, ...state.editCitizen, }),
    ({ ...EditCitizenStores.actionCreators, ...Stores.actionCreators, }))
    (LogoutAzure);