import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as SigIn from '../../models/signin/Signin';
import { error } from 'console';
import * as ForgotPassword from '../../models/signin/ForgotPassword';

interface BeforeGetForgotPassword {
    type: 'GET_FORGOTPASSWORD';
}

interface GetForgotPassword {
    type: 'FORGOTPASSWORD_SUCCESS';
    status: number
}

interface FailGetForgotPassword {
    type: 'FORGOTPASSWORD_FAIL'
    message: string,
    status: number
}

interface CLEARForgotPassword {
    type: 'FORGOTPASSWORD_CLEAR'
}

type KnownAction = BeforeGetForgotPassword | GetForgotPassword | FailGetForgotPassword | CLEARForgotPassword

export const actionCreators = {
    requestForgotPassword: (bool: boolean, method: string, req: ForgotPassword.ReqForgotPassword, redirect: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('actionCreators : requestForgotPassword')
        const appState = getState();
        console.log(req)
        console.log(redirect)
        if (appState && appState.ForgotPassword) {
            if (bool && method == "GET") {
                if (redirect == undefined) {
                    redirect = ""
                }
                fetch(`/v1/citizen/forgotpassword?redirect=` + redirect, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(req)
                })
                    .then(response => {
                        return response
                    })
                    .then(data => {
                        if (!data.ok) {
                            data.json().then(err => {
                                console.log(err.message)
                                dispatch({ type: 'FORGOTPASSWORD_FAIL', status: 204, message: err.message });
                            })
                        } else {
                            console.log(data)
                            dispatch({ type: 'FORGOTPASSWORD_SUCCESS', status: 200 });
                        }
                    })
                dispatch({ type: 'GET_FORGOTPASSWORD' });
            } else if (method == "CLEAR") {
                dispatch({ type: 'FORGOTPASSWORD_CLEAR' });
            }
        }
    }

};

const unloadedState: ForgotPassword.GetForgotPasswordState = { isLoadGetForgotPassword: false, responseGetForgotPassword: '', statusGetForgotPassword: 0, messageGetForgotPassword: '' };

export const reducer: Reducer<ForgotPassword.GetForgotPasswordState> = (state: ForgotPassword.GetForgotPasswordState | undefined, incomingAction: Action): ForgotPassword.GetForgotPasswordState => {
    if (state === undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'GET_FORGOTPASSWORD':
            console.log('reducer GET_EMAILAUTHEN')
            return {
                isLoadGetForgotPassword: false
            };
        case 'FORGOTPASSWORD_SUCCESS':
            console.log('reducer EMAILAUTHEN_SUCCESS')
            return {
                isLoadGetForgotPassword: true,
                statusGetForgotPassword: 200
            };
        case 'FORGOTPASSWORD_FAIL':
            console.log('reducer EMAILAUTHEN_FAIL')
            return {
                isLoadGetForgotPassword: true,
                messageGetForgotPassword: action.message,
                statusGetForgotPassword: 400
            };
        case 'FORGOTPASSWORD_CLEAR':
            console.log('reducer EMAILAUTHEN_CLEAR')
            return {
                isLoadGetForgotPassword: false,
                messageGetForgotPassword: '',
                statusGetForgotPassword: 0,
                responseGetForgotPassword: ''
            };

        default: return state;
    }
};