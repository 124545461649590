import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as ResetPassword from '../../models/signin/ResetPassword';

interface BeforeGetResetPassword {
    type: 'GET_RESETPASSWORD';
}

interface GetResetPassword {
    type: 'RESETPASSWORD_SUCCESS';
    status: number
}

interface FailGetResetPassword {
    type: 'RESETPASSWORD_FAIL'
    message: string,
    status: number
}

interface CLEARResetPassword {
    type: 'RESETPASSWORD_CLEAR'
}

type KnownAction = BeforeGetResetPassword | GetResetPassword | FailGetResetPassword | CLEARResetPassword

export const actionCreators = {
    requestResetPassword: (bool: boolean, method: string, req: ResetPassword.ReqResetPassword, reset_key : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('actionCreators : requestResetPassword')
        console.log(JSON.stringify(req))
        var data = JSON.stringify(req)
        const appState = getState();
        if (appState && appState.ResetPassword) {
            if (bool && method == "GET") {
                fetch(`/v1/citizen/resetpassword?reset_key=` + reset_key, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: data
                })
                    .then(response => {
                        return response
                    })
                    .then(data => {
                        if (!data.ok) {
                            data.json().then(err => {
                                console.log(err.message)
                                dispatch({ type: 'RESETPASSWORD_FAIL', status: 204, message: err.message });
                            })
                        } else {
                            console.log(data)
                            dispatch({ type: 'RESETPASSWORD_SUCCESS', status: 200 });
                        }
                    })
                dispatch({ type: 'GET_RESETPASSWORD' });
            } else if (method == "CLEAR") {
                dispatch({ type: 'RESETPASSWORD_CLEAR' });
            }
        }
    }

};

const unloadedState: ResetPassword.GetResetPasswordState = { isLoadGetResetPassword: false, responseGetResetPassword: '', statusGetResetPassword: 0, messageGetResetPassword: '' };

export const reducer: Reducer<ResetPassword.GetResetPasswordState> = (state: ResetPassword.GetResetPasswordState | undefined, incomingAction: Action): ResetPassword.GetResetPasswordState => {
    if (state === undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'GET_RESETPASSWORD':
            console.log('reducer GET_EMAILAUTHEN')
            return {
                isLoadGetResetPassword: false
            };
        case 'RESETPASSWORD_SUCCESS':
            console.log('reducer EMAILAUTHEN_SUCCESS')
            return {
                isLoadGetResetPassword: true,
                statusGetResetPassword: 200
            };
        case 'RESETPASSWORD_FAIL':
            console.log('reducer EMAILAUTHEN_FAIL')
            return {
                isLoadGetResetPassword: true,
                messageGetResetPassword: action.message,
                statusGetResetPassword: 400
            };
        case 'RESETPASSWORD_CLEAR':
            console.log('reducer EMAILAUTHEN_CLEAR')
            return {
                isLoadGetResetPassword: false,
                messageGetResetPassword: '',
                statusGetResetPassword: 0,
                responseGetResetPassword: ''
            };

        default: return state;
    }
};