import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as EditCitizenModel from '../models/EditCitizen';

interface RequestFunctionAction {
    type: 'REQUEST_EditCitizen';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_EditCitizen';
    model: EditCitizenModel.EditCitizen;
}

interface RequestFunctionActionSave {
    type: 'REQUEST_SAVE_EditCitizen';
}

interface ReceiveFunctionActionSave {
    type: 'RECEIVE_SAVE_EditCitizen';
    model: EditCitizenModel.EditCitizen;
}


type KnownAction = RequestFunctionAction | ReceiveFunctionAction | RequestFunctionActionSave | ReceiveFunctionActionSave

export const actionCreators = {
    /*requestFunction: (pageload: boolean, sid: string, version: number, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {*/
    requestFunctionGet: (sid: string, body: EditCitizenModel.EditCitizen, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        /*console.log(pageload + "-" + sid  + "-" + version + "-" + method);*/
        console.log(method);
        console.log(localStorage.getItem('SSO-WEB_APPLICATION'));
        if (method == "GET") {

            fetch(`/v1/citizen` , {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO-WEB_APPLICATION'),
                },
            })
                .then(response => {
                    return response.json() as Promise<EditCitizenModel.EditCitizen>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_EditCitizen', model: data });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_EditCitizen' });
                });
        }

        if (method == "PUT") {

            fetch(`/v1/citizen`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO-WEB_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response.json() as Promise<EditCitizenModel.EditCitizen>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_SAVE_EditCitizen', model: data });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_SAVE_EditCitizen' });
                });
        }

        if (method == "") {
            dispatch({ type: 'REQUEST_SAVE_EditCitizen' });
        }

    }
};

const unloadedState: EditCitizenModel.EditCitizenMapState = { isLoading: false, response: undefined, isSuccess:false };

export const reducer: Reducer<EditCitizenModel.EditCitizenMapState> = (state: EditCitizenModel.EditCitizenMapState | undefined, incomingAction: Action): EditCitizenModel.EditCitizenMapState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_EditCitizen':
            return {
                isLoading: false,
            };
        case 'RECEIVE_EditCitizen':
            return {
                isLoading: true,
                response: action.model,
            };
        case 'REQUEST_SAVE_EditCitizen':
            return {
                isSuccess: false,
                isLoading: false
            };
        case 'RECEIVE_SAVE_EditCitizen':
            return {
                isSuccess: true,
                response: action.model,
            };

        default: return state;
    }
};