import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Models from '../../models/EditCitizen';
import * as Stores from '../../store/EditCitizen';
import * as SigninModels from '../../models/signin/Signin';
import * as SigninStores from '../../store/signin/Signin';
import Swal from 'sweetalert2'
import * as QueryString from "query-string"

type Store = Models.EditCitizenState & typeof Stores.actionCreators;
type SigninStore = SigninModels.SigninState & typeof SigninStores.actionCreators;


class EditCitizenComponent extends React.PureComponent<any, { modelListCitizen: Models.EditCitizen, isLoad: boolean }, SigninStore & Store> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoad: false,
            modelListCitizen: {
                citizen_id: "",
                username: "",
                national_id: "",
                first_name: "",
                last_name: "",
                first_name_en: "",
                last_name_en: "",
                nickname: "",
                gender: "",
                birth_date: "",
                mobile_phone_number: "",
                email: "",
                facebook_id: "",
                line_id: "",
                google_id: "",
                azure_id: "",
                avatar: ""
            },
        }
        this.save = this.save.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
    }

    public componentDidMount() {
        const { token } = QueryString.parse(this.props.location.search);
        if (token != null) {
            localStorage.setItem('SSO-WEB_APPLICATION', token);
        }
        //var jsonToken = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFycGp5STBPUjRNUlV6bHdPM3R5RW5XNFNMciIsInNlciI6IlMwMDEzIiwiYXBwIjoiMUlidUFTbXBxSWFwam1oVmZ6Y0xGNFhQVlk5Iiwia2V5IjoiMWZsU1hRc3dVcnBxcFFGVHVHOUQ0cDE4Q2ZYIiwiZXh0cmEiOiJ7XCJjaXRpemVuX2lkXCI6XCJ3ZjAwMVwiLFwidXNlcl9pZFwiOm51bGx9IiwiZXhwIjoxNjIyMjcyMTc3LCJpYXQiOjE2MTk2ODAxNzcsImlzcyI6IlNPTkFSIElOTk9WQVRJT04gQ08uLCBMVEQuIn0.J3YktsbQ5GnQBFm_UAdOpu3oFl6sz1OHzrdkc2sZulpKjzk89f85fnLSdjJ18of_zkI6dWoIUlB8GwgSrfvjtIiXyTd4tUm5PP96ws2q-7fd3tEMJ5TUiEdB0MuLznV2Bz-HEbXBTeprfURK_PA-ctZnQzSZ3sWyFgwpUQiPlSk';
        
        

        this.props.requestFunctionGet("", "", "");
        this.props.requestFunctionGet("", "", "GET");
    }

    private save() {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.props.requestFunctionGet("", this.state.modelListCitizen, "PUT");
    }

    private handleChangeValue(e: any) {
        var data = this.state.modelListCitizen;
        /*data[e.target.id] = e.target.value;*/
        if (e.target.id == "national_id") {
            data.national_id = e.target.value;
        }
        if (e.target.id == "first_name") {
            data.first_name = e.target.value;
        }
        if (e.target.id == "last_name") {
            data.last_name = e.target.value;
        }
        if (e.target.id == "first_name_en") {
            data.first_name_en = e.target.value;
        }
        if (e.target.id == "last_name_en") {
            data.last_name_en = e.target.value;
        }
        if (e.target.id == "nickname") {
            data.nickname = e.target.value;
        }
        if (e.target.id == "gender") {
            data.gender = e.target.value;
        }
        if (e.target.id == "birth_date") {
            data.birth_date = e.target.value;
        }
        if (e.target.id == "mobile_phone_number") {
            data.mobile_phone_number = e.target.value;
        }
        if (e.target.id == "email") {
            data.email = e.target.value;
        }
        if (e.target.id == "facebook_id") {
            data.facebook_id = e.target.value;
        }
        if (e.target.id == "line_id") {
            data.line_id = e.target.value;
        }
        if (e.target.id == "google_id") {
            data.google_id = e.target.value;
        }
        if (e.target.id == "azure_id") {
            data.azure_id = e.target.value;
        }
        if (e.target.id == "avatar") {
            data.avatar = e.target.value;
        }

        this.setState({
            modelListCitizen: data
        });

        /*console.log(this.state.modelRegister);*/
    }

    render() {
        const { response } = this.props
        console.log("response");
        console.log(this.props.isLoading);
        console.log(this.props.isSuccess);
        console.log(this.props.response);

        
        if (this.props.isLoading == true) {
            console.log("get success");
        }
        if (this.props.isSuccess) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            if (response != null) {
                if (response != undefined) {
                    console.log("save success");
                    this.props.requestFunctionGet("", "", "");
                    Swal.fire({
                        title: 'Success',
                        text: 'บันทึกข้อมูลสำเร็จ',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ยืนยัน!'
                    }).then((result) => {
                        if (result.value) {
                            //return <Redirect to='/' />
                            window.location.href = "/"
                        }
                    })
                }
                else {
                    this.props.requestFunctionGet("", "", "");
                    Swal.fire({
                        title: 'Error',
                        text: 'บันทึกข้อมูลไม่สำเร็จ',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ยืนยัน!'
                    }).then((result) => {
                        if (result.value) {
                        }
                    })
                }
            }
            
        }
        if (this.props.response != null ) {
            if (this.props.response != null) {
                var getData = response;
                var data = this.state.modelListCitizen;

                data.username = getData.username;
                data.national_id = getData.national_id;
                data.first_name = getData.first_name;
                data.last_name = getData.last_name;
                data.first_name_en = getData.first_name_en;
                data.last_name_en = getData.last_name_en;
                data.nickname = getData.nickname;
                data.gender = getData.gender;
                data.birth_date = getData.birth_date;
                data.mobile_phone_number = getData.mobile_phone_number;
                data.email = getData.email;
                data.facebook_id = getData.facebook_id;
                data.line_id = getData.line_id;
                data.google_id = getData.google_id;
                data.azure_id = getData.azure_id;
                data.avatar = getData.avatar;

                this.setState({
                    modelListCitizen: data
                });
                //console.log(this.state.modelListCitizen);
            } else {
                this.setState({
                    modelListCitizen: {
                        citizen_id: "",
                        username: "",
                        national_id: "",
                        first_name: "",
                        last_name: "",
                        first_name_en: "",
                        last_name_en: "",
                        nickname: "",
                        gender: "",
                        birth_date: "",
                        mobile_phone_number: "",
                        email: "",
                        facebook_id: "",
                        line_id: "",
                        google_id: "",
                        azure_id: "",
                        avatar: ""
                    },
                });
            }
            this.setState({ isLoad: true });
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }
        console.log("modelListCitizen");
        console.log(this.state.modelListCitizen);
        console.log("---");

        return (
            <div >
                <div className="row" style={{ paddingTop: "20px" }}>
                    <div className="col-lg-2"></div>
                    <div className="col-8">
                        <div>
                            <h1>Edit Citizen</h1>
                        </div>
                        <div style={{ paddingTop: "30px" }}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>First Name</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="First Name" type="text" defaultValue={this.state.modelListCitizen.first_name} id="first_name" onChange={this.handleChangeValue} />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Last Name</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Last Name" type="text" defaultValue={this.state.modelListCitizen.last_name} id="last_name" onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>First Name English</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="First Name English" type="text" defaultValue={this.state.modelListCitizen.first_name_en} id="first_name_en" onChange={this.handleChangeValue} />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Last Name English</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Last Name English" type="text" defaultValue={this.state.modelListCitizen.last_name_en} id="last_name_en" onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Nickname</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Nickname" type="text" defaultValue={this.state.modelListCitizen.nickname} id="nickname" onChange={this.handleChangeValue} />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>National ID</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="National ID" type="text" defaultValue={this.state.modelListCitizen.national_id} id="national_id" onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Gender</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Gender" type="text" defaultValue={this.state.modelListCitizen.gender} id="gender" onChange={this.handleChangeValue} />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Birth Date</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Birth Date" type="text" defaultValue={this.state.modelListCitizen.birth_date} id="birth_date" onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>E-mail</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="E-mail" type="text" defaultValue={this.state.modelListCitizen.email} id="email" onChange={this.handleChangeValue} />
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Phone Number</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Phone Number" type="text" defaultValue={this.state.modelListCitizen.mobile_phone_number} id="mobile_phone_number" onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Facebook ID</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Facebook ID" type="text" defaultValue={this.state.modelListCitizen.facebook_id} id="facebook_id" readOnly onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Line ID</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Line ID" type="text" defaultValue={this.state.modelListCitizen.line_id} id="line_id" readOnly onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Google ID</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Google ID" type="text" defaultValue={this.state.modelListCitizen.google_id} id="google_id" readOnly onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Azure ID</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Azure ID" type="text" defaultValue={this.state.modelListCitizen.azure_id} id="azure_id" readOnly onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Avatar</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Avatar" type="text" defaultValue={this.state.modelListCitizen.avatar} id="avatar" onChange={this.handleChangeValue} />
                            </InputGroup>
                            <br />
                            <button type="submit" onClick={this.save} className="mt-3 btn btn-info btn-block"><b>Save</b></button>
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        );
    }
}


export default connect((state: ApplicationState) =>
    ({ ...state.signin, ...state.editCitizen, }),
    ({ ...SigninStores.actionCreators, ...Stores.actionCreators, }))
    (EditCitizenComponent)